<template>
  <div class="addResourceOrder subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>商品审核</p>
      </div>
      <div class="addButton">
        <el-button type="danger" @click="showAddDialog()">新增商品</el-button>
      </div>
    </div>
    <div class="productList">
      <el-table :data="goodsDatas" border>
        <el-table-column prop="code" label="商品编码"></el-table-column>
        <el-table-column prop="name" label="商品分类"></el-table-column>
        <el-table-column prop="origin" label="产地"></el-table-column>
        <el-table-column prop="price" label="初始价"></el-table-column>
        <el-table-column prop="quantity" label="初始仓数量"></el-table-column>
        <el-table-column prop="marketPrice" label="市场价"></el-table-column>
        <el-table-column prop="maxHold" label="最大持仓"></el-table-column>
        <el-table-column prop="value" label="状态"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.statusCode == 2"
              type="danger"
              size="mini"
              @click="onMarket(scope.row.commodityId)"
              >上市</el-button
            >
            <el-button @click="showDetails(scope.row)" size="mini"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
    <el-dialog
      v-dialogDrag
      :title="dialogTitle"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="900px"
    >
      <div class="addResourceOrderForm">
        <el-form
          ref="form"
          :rules="rules"
          :model="form"
          label-position="top"
          :disabled="isDisabledForm"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="商品编码" prop="code">
                <el-input v-model="form.code"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商品分类" prop="name">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="商品类别" prop="type">
                <el-cascader
                  class="width-100"
                  v-model="form.type"
                  :options="categoryOptions"
                  :props="categoryProps"
                ></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="商品图片" prop="image">
                <el-upload
                  class="avatar-uploader"
                  :action="actionUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :data="postData"
                >
                  <img v-if="form.image" :src="form.image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="买手续费率" prop="buyFeeRate">
                <el-input v-model="form.buyFeeRate"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="卖手续费率" prop="sellFeeRate">
                <el-input v-model="form.sellFeeRate"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="下单是否为整数倍数" prop="integerMultiples">
                <el-radio-group v-model="form.integerMultiples">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="交收是否为整数倍" prop="deliveryMultiples">
                <el-radio-group v-model="form.deliveryMultiples">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="手续费收取方式" prop="feeAlgr">
                <el-radio-group v-model="form.feeAlgr">
                  <el-radio :label="1">比例</el-radio>
                  <el-radio :label="2">固定值</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="履约订金收取方式" prop="marginAlgr">
                <el-radio-group v-model="form.marginAlgr">
                  <el-radio :label="1">比例</el-radio>
                  <el-radio :label="2">固定值</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="履约订金率" prop="marginRate">
                <el-input v-model="form.marginRate"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="单笔最大买入" prop="singleMax">
                <el-input v-model="form.singleMax"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单笔最小买入" prop="singleMin">
                <el-input v-model="form.singleMin"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="交易单位" prop="tradeUnit">
                <el-input v-model="form.tradeUnit"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="交易单位名称" prop="tradeUnitName">
                <el-input v-model="form.tradeUnitName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="尾款付款期限" prop="lastPayDay">
                <el-input v-model="form.lastPayDay">
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="市场价" prop="marketPrice">
                <el-input v-model="form.marketPrice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最大持仓" prop="maxHold">
                <el-input v-model="form.maxHold"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="最小交收单位" prop="minDeliveryUnit">
                <el-input v-model="form.minDeliveryUnit"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="产地" prop="origin">
                <el-input v-model="form.origin"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="初始价" prop="price">
                <el-input v-model="form.price"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="初始仓数量" prop="quantity">
                <el-input v-model="form.quantity"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="商品备注" prop="note">
                <el-input v-model="form.note"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="仓库" prop="warehouse">
                <el-input v-model="form.warehouse"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" v-if="isShowOperateButton">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmAddProduct()"
          :loading="isAddLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      dialogVisible: false,
      goodsItemdialogVisible: false,
      goodsDatas: [
        // {
        //   buyFeeRate: "1",
        //   code: "1",
        //   deliveryMultiples: 1,
        //   feeAlgr: 1,
        //   image: "1",
        //   integerMultiples: 1,
        //   lastPayDay: "1",
        //   marginAlgr: 1,
        //   marginRate: "1",
        //   marketDate: "2",
        //   marketPrice: "1",
        //   maxHold: "1",
        //   minDeliveryUnit: "1",
        //   name: "1",
        //   note: "1",
        //   origin: "1",
        //   price: "￥20",
        //   quantity: "1",
        //   sellFeeRate: "1",
        //   singleMax: "1",
        //   singleMin: "1",
        //   statusCode: "1",
        //   storeId: "1",
        //   tradeStatus: "1",
        //   tradeUnit: "1",
        //   tradeUnitName: "1",
        //   statusCode: 2,
        //   warehouse: "1",
        //   value: "已通过"
        // }
      ],
      form: {
        buyFeeRate: "",
        code: "",
        deliveryMultiples: 1,
        feeAlgr: 1,
        image: "",
        integerMultiples: 1,
        lastPayDay: "",
        marginAlgr: 1,
        marginRate: "",
        marketDate: "",
        marketPrice: "",
        maxHold: "",
        minDeliveryUnit: "",
        name: "",
        note: "",
        origin: "",
        price: "",
        quantity: "",
        sellFeeRate: "",
        singleMax: "",
        singleMin: "",
        statusCode: "",
        storeId: "",
        tradeStatus: "",
        tradeUnit: "",
        tradeUnitName: "",
        type: "",
        warehouse: ""
      },
      rules: {
        code: [
          { required: true, message: "请输入商品编码", trigger: "change" }
        ],
        name: [
          { required: true, message: "请输入商品分类", trigger: "change" }
        ],
        type: [
          { required: true, message: "请选择商品类别", trigger: "change" }
        ],
        image: [
          { required: true, message: "请上传商品图片", trigger: "change" }
        ],
        buyFeeRate: [
          { required: true, message: "请输入买手续费率", trigger: "change" }
        ],
        integerMultiples: [
          {
            required: true,
            message: "请选择下单是否为整数倍数",
            trigger: "change"
          }
        ],
        deliveryMultiples: [
          {
            required: true,
            message: "请选择交收是否为整数倍数",
            trigger: "change"
          }
        ],
        feeAlgr: [
          { required: true, message: "请选择手续费收取方式", trigger: "change" }
        ],
        sellFeeRate: [
          { required: true, message: "请输入卖手续费率", trigger: "change" }
        ],
        marginAlgr: [
          {
            required: true,
            message: "请选择履约订金收取方式",
            trigger: "change"
          }
        ],
        marginRate: [
          { required: true, message: "请填写履约订金率", trigger: "change" }
        ],
        singleMax: [
          { required: true, message: "请填写单笔最大买入", trigger: "change" }
        ],
        singleMin: [
          { required: true, message: "请填写单笔最小买入", trigger: "change" }
        ],
        tradeUnit: [
          { required: true, message: "请填写交易单位", trigger: "change" }
        ],
        tradeUnitName: [
          { required: true, message: "请填写交易单位名称", trigger: "change" }
        ],
        lastPayDay: [
          { required: true, message: "请填写尾款付款期限", trigger: "change" }
        ],
        maxHold: [
          { required: true, message: "请填写最大持仓", trigger: "change" }
        ],
        minDeliveryUnit: [
          { required: true, message: "请填写最小交收单位", trigger: "change" }
        ],
        marketPrice: [
          { required: true, message: "请输入市场价", trigger: "change" }
        ],
        meterageUnit: [
          { required: true, message: "请输入计量单位", trigger: "change" }
        ],
        note: [
          { required: true, message: "请输入商品备注", trigger: "change" }
        ],
        origin: [{ required: true, message: "请输入产地", trigger: "change" }],
        price: [{ required: true, message: "请输入初始价", trigger: "change" }],
        quantity: [
          { required: true, message: "请输入初始仓数量", trigger: "change" }
        ],
        resourceChannel: [
          { required: true, message: "请输入资源渠道", trigger: "change" }
        ],
        warehouse: [
          { required: true, message: "请输入仓库", trigger: "change" }
        ]
      },
      categoryOptions: [],
      categoryProps: {
        label: "Value",
        value: "Code",
        checkStrictly: true
      },
      dialogTitle: "",
      isDisabledForm: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      isShowOperateButton: false,
      isAddLoading: false
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getDatas();
    this.getCommodityCategory();
  },
  methods: {
    getCommodityCategory() {
      http.postFront(protocolFwd.param_getCommodityCategory).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.categoryOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_selectLtCommodity.param.page = this.current - 1;
      protocolFwd.param_selectLtCommodity.param.size = this.pageSize;
      protocolFwd.param_selectLtCommodity.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_selectLtCommodity.param.statusCode = null;
      http.postFront(protocolFwd.param_selectLtCommodity).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.goodsDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showAddDialog() {
      this.isDisabledForm = false;
      this.dialogTitle = "新增商品";
      this.dialogVisible = true;
      this.isShowOperateButton = true;
    },
    handleAvatarSuccess(res, file) {
      this.form.image = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    showDetails(item) {
      this.isDisabledForm = true;
      this.dialogTitle = "查看商品详情";
      this.form = JSON.parse(JSON.stringify(item));
      this.dialogVisible = true;
      this.isShowOperateButton = false;
    },
    handleClose() {
      this.form = {
        buyFeeRate: "",
        code: "",
        deliveryMultiples: 1,
        feeAlgr: 1,
        image: "",
        integerMultiples: 1,
        lastPayDay: "",
        marginAlgr: 1,
        marginRate: "",
        marketDate: "",
        marketPrice: "",
        maxHold: "",
        minDeliveryUnit: "",
        name: "",
        note: "",
        origin: "",
        price: "",
        quantity: "",
        sellFeeRate: "",
        singleMax: "",
        singleMin: "",
        statusCode: "",
        storeId: "",
        tradeStatus: "",
        tradeUnit: "",
        tradeUnitName: "",
        type: "",
        warehouse: ""
      };
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.dialogVisible = false;
    },
    confirmAddProduct() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (Array.isArray(this.form.type)) {
            this.form.type = this.form.type[this.form.type.length - 1];
          } else {
            this.form.type = this.form.type;
          }
          protocolFwd.param_addCommodityTwo.param = this.form;
          this.isAddLoading = true;
          http
            .postFront(protocolFwd.param_addCommodityTwo)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                this.dialogVisible = false;
                this.$EL_MESSAGE("提交成功！待后台审核成功后方可上市");
                this.$refs["form"].resetFields();
                this.getDatas();
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    onMarket(commodityId) {
      this.$confirm("请确认是否上市？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_marketCommodity.param.commodityId = commodityId;
          http.postFront(protocolFwd.param_marketCommodity).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
          // 演示用
          // this.$EL_MESSAGE("上市成功");
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.productList {
  margin-top: 20px;
}
.subPage .pagination {
  margin-top: 15px;
}
/* 头像 开始 */
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.submitIdentification {
  text-align: center;
}
/* 头像 结束 */
</style>
